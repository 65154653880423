import { Injectable } from '@angular/core';
import { type EmployeeVoluntarilyInsuredForm, type Premium, type TaxonomyData } from '../models/insurant-calculator.model';
import { CalculatorService } from './calculator.service';

@Injectable({
    providedIn: 'root',
})
export class EmployeeVoluntarilyInsuredCalculatorService {
    private calculator!: CalculatorService;

    public calculatePremium(formData: EmployeeVoluntarilyInsuredForm, taxonomies: TaxonomyData): Premium {
        this.calculator = new CalculatorService(taxonomies);

        const { hasChildren, stateOfResidency } = formData;
        const childrenAmount = formData.childrenUnder25 ?? 0;
        const residesInSaxony = stateOfResidency === 'Sachsen';

        const {
            requiredBasis,
            showMinimumAssessmentBasis,
            showContributionAssessmentLimit,
            supplementAmount = 0,
        } = this.calculator.getCalculationBasis(formData.monthlyGrossIncome);

        const incomeBasis = requiredBasis + supplementAmount;

        const healthInsuranceEmployerShare = this.calculator.getHealthInsuranceShare(incomeBasis);
        const healthInsuranceDeductible = healthInsuranceEmployerShare;

        const nursingCareInsuranceEmployerShare = this.calculator.getNursingCareInsuranceEmployerShare(incomeBasis, residesInSaxony);
        const nursingCareInsuranceDeductible = this.calculator.getNursingCareInsuranceDeductible(
            hasChildren,
            childrenAmount,
            nursingCareInsuranceEmployerShare,
            incomeBasis,
        );

        return this.calculator.getPremium(
            healthInsuranceEmployerShare,
            0,
            healthInsuranceDeductible,
            nursingCareInsuranceEmployerShare,
            nursingCareInsuranceDeductible,
            {
                showContributionAssessmentLimit,
                showMinimumAssessmentBasis,
            },
        );
    }
}
