/* eslint-disable @typescript-eslint/naming-convention */
import { Injectable } from '@angular/core';
import { type ServiceToolsModel } from '@big-direkt/utils/shared';
import { type TaxonomyData } from '../models/insurant-calculator.model';
import { type ContributionCalculatorTaxonomyNamesGerman } from './../models/contribution-calculator-taxonomies.model';

@Injectable({
    providedIn: 'root',
})
export class ContributionCalculatorTaxonomyMapper {
    private readonly taxonomyNameTranslations: Record<ContributionCalculatorTaxonomyNamesGerman, keyof TaxonomyData> = {
        abschlag_pflegeversicherung_1_kind: 'nursingCareDiscountOneChild',
        abschlag_pflegeversicherung_2_kinder: 'nursingCareDiscountTwoChildren',
        abschlag_pflegeversicherung_3_kinder: 'nursingCareDiscountThreeChildren',
        abschlag_pflegeversicherung_4_kinder: 'nursingCareDiscountFourChildren',
        abschlag_pflegeversicherung_5_kinder: 'nursingCareDiscountFiveChildren',
        beitragsbemessungsgrenze: 'contributionAssessmentLimit',
        beitragssatz_krankenversicherung: 'healthInsuranceContributionRate',
        beitragssatz_krankenversicherung_ermaessigt: 'healthInsuranceContributionRateDiscounted',
        zusatzbeitrag_krankenversicherung: 'additionalHealthInsuranceContributionRate',
        beitragssatz_pflegeversicherung: 'nursingCareInsuranceContributionRate',
        zusatzbeitrag_pflegeversicherung_kinderlos: 'additionalNursingCareInsuranceContributionRate',
        gleitzonen_obergrenze: 'slidingPayScaleCeiling',
        gleitzonen_untergrenze: 'slidingPayScaleFloor',
        mindest_einkommen_selbststaendig: 'minimumIncomeSelfEmployed',
        mindest_einkommen_betriebsrente: 'minimumIncomeCompanyPension',
    };

    public mapTaxonomies(rawTaxonomies: ServiceToolsModel[]): TaxonomyData {
        const taxonomies: TaxonomyData = {
            nursingCareDiscountOneChild: 0,
            nursingCareDiscountTwoChildren: 0,
            nursingCareDiscountThreeChildren: 0,
            nursingCareDiscountFourChildren: 0,
            nursingCareDiscountFiveChildren: 0,
            slidingPayScaleCeiling: 0,
            slidingPayScaleFloor: 0,
            healthInsuranceContributionRate: 0,
            healthInsuranceContributionRateDiscounted: 0,
            additionalHealthInsuranceContributionRate: 0,
            nursingCareInsuranceContributionRate: 0,
            additionalNursingCareInsuranceContributionRate: 0,
            contributionAssessmentLimit: 0,
            minimumIncomeSelfEmployed: 0,
            minimumIncomeCompanyPension: 0,
        };

        const filteredNames: ContributionCalculatorTaxonomyNamesGerman[] = [
            'abschlag_pflegeversicherung_1_kind',
            'abschlag_pflegeversicherung_2_kinder',
            'abschlag_pflegeversicherung_3_kinder',
            'abschlag_pflegeversicherung_4_kinder',
            'abschlag_pflegeversicherung_5_kinder',
            'beitragsbemessungsgrenze',
            'beitragssatz_krankenversicherung_ermaessigt',
            'beitragssatz_krankenversicherung',
            'beitragssatz_pflegeversicherung',
            'gleitzonen_obergrenze',
            'gleitzonen_untergrenze',
            'mindest_einkommen_betriebsrente',
            'mindest_einkommen_selbststaendig',
            'zusatzbeitrag_krankenversicherung',
            'zusatzbeitrag_pflegeversicherung_kinderlos',
        ];

        rawTaxonomies.forEach(taxonomy => {
            if (filteredNames.includes(taxonomy.name as Partial<ContributionCalculatorTaxonomyNamesGerman>)) {
                const mappedName = this.translateName(taxonomy.name as ContributionCalculatorTaxonomyNamesGerman);
                const value = parseFloat(taxonomy.replacement.replace(',', '.'));

                taxonomies[mappedName] = value;
            }
        });

        return taxonomies;
    }

    private translateName(germanName: ContributionCalculatorTaxonomyNamesGerman): keyof TaxonomyData {
        return this.taxonomyNameTranslations[germanName];
    }
}
