<ng-container *transloco="let t; prefix: 'stContributionCalculator'">
    @if (calculationResult.hasSlidingPayScale) {
        <h3>{{ t('slidingPayScaleCeiling.title') }}</h3>
        <p>
            <strong>{{ t('slidingPayScaleCeiling.important') }}</strong>
        </p>
        <p>
            {{
                t('slidingPayScaleCeiling.paragraph_1', {
                    aboveSlidingPayScaleFloor: taxonomies.slidingPayScaleFloor + 0.01 | number: '1.2-2',
                    slidingPayScaleCeiling: taxonomies.slidingPayScaleCeiling | number: '1.2-2',
                })
            }}
        </p>
        <p [innerHTML]="t('slidingPayScaleCeiling.paragraph_2', {
            number: servicePhoneNumber.title
        })"></p>
    }

    @if (calculationResult.isMarginallyEmployed) {
        <h3>
            {{ t('isMarginallyEmployed.title', { slidingPayScaleFloor: taxonomies.slidingPayScaleFloor | number: '1.2-2' }) }}
        </h3>
        <p>
            <strong>
                {{ t('isMarginallyEmployed.important', { slidingPayScaleFloor: taxonomies.slidingPayScaleFloor | number: '1.2-2' }) }}
            </strong>
        </p>
        <p [innerHTML]="t('isMarginallyEmployed.paragraph_1', {
            number: servicePhoneNumber.title
        })"></p>
        <p [innerHTML]="t('isMarginallyEmployed.paragraph_2')"></p>
    }

    @if (!calculationResult.isMarginallyEmployed) {
        <big-ui-table
            class="mt-10 mb-4"
            [headers]="tableHeaders"
            [customContent]="true">
            <!-- Krankenversicherung -->
            @if (calculationResult.monthlyContribution.healthInsurance; as healthInsurance) {
                <tr
                    [bigUiTableRow]
                    class="block md:table-row">
                    <td
                        [bigUiTableCell]
                        class="block md:table-cell">
                        <strong>{{ t('results.monthlyAmountYear', { year: calculationResult.year }) }}</strong>
                    </td>
                    <td
                        [bigUiTableCell]
                        class="block md:table-cell">
                        <strong>{{ t('results.healthInsuranceContributionRate') }}</strong>
                    </td>
                    <td
                        [bigUiTableCell]
                        class="block text-right md:table-cell">
                        <strong>{{ healthInsurance.total | currency: 'EUR' }}</strong>
                    </td>
                </tr>
                @if (healthInsurance.employerShare) {
                    <tr
                        [bigUiTableRow]
                        class="block md:table-row">
                        <td
                            [bigUiTableCell]
                            class="hidden md:table-cell"></td>
                        <td
                            [bigUiTableCell]
                            class="block md:table-cell">
                            {{ t('results.employerContribution') }}
                        </td>
                        <td
                            [bigUiTableCell]
                            class="block text-right md:table-cell">
                            {{ healthInsurance.employerShare | currency: 'EUR' }}
                        </td>
                    </tr>
                }
                @if (healthInsurance.pensionInsuranceShare) {
                    <tr
                        [bigUiTableRow]
                        class="block md:table-row">
                        <td
                            [bigUiTableCell]
                            class="hidden md:table-cell"></td>
                        <td
                            [bigUiTableCell]
                            class="block md:table-cell">
                            {{ t('results.pensionInsuranceCarrierContribution') }}
                        </td>
                        <td
                            [bigUiTableCell]
                            class="block text-right md:table-cell">
                            {{ healthInsurance.pensionInsuranceShare | currency: 'EUR' }}
                        </td>
                    </tr>
                }
                @if (healthInsurance.deductible) {
                    <tr
                        [bigUiTableRow]
                        class="block md:table-row">
                        <td
                            [bigUiTableCell]
                            class="hidden md:table-cell"></td>
                        <td
                            [bigUiTableCell]
                            class="block md:table-cell">
                            {{ t('results.deductible') }}
                        </td>
                        <td
                            [bigUiTableCell]
                            class="block text-right md:table-cell">
                            {{ healthInsurance.deductible | currency: 'EUR' }}
                        </td>
                    </tr>
                }
            }
            <!-- Pflegeversicherung -->
            @if (calculationResult.monthlyContribution.nursingCareInsurance; as nursingCareInsurance) {
                <tr
                    [bigUiTableRow]
                    class="block md:table-row">
                    <td
                        [bigUiTableCell]
                        class="hidden md:table-cell"></td>
                    <td
                        [bigUiTableCell]
                        class="block md:table-cell">
                        <strong>{{ t('results.nursingCareInsuranceRate') }}</strong>
                    </td>
                    <td
                        [bigUiTableCell]
                        class="block text-right md:table-cell">
                        <strong>{{ nursingCareInsurance.total | currency: 'EUR' }}</strong>
                    </td>
                </tr>
                @if (nursingCareInsurance.employerShare) {
                    <tr
                        [bigUiTableRow]
                        class="block md:table-row">
                        <td
                            [bigUiTableCell]
                            class="hidden md:table-cell"></td>
                        <td
                            [bigUiTableCell]
                            class="block md:table-cell">
                            {{ t('results.employerContribution') }}
                        </td>
                        <td
                            [bigUiTableCell]
                            class="block text-right md:table-cell">
                            {{ nursingCareInsurance.employerShare | currency: 'EUR' }}
                        </td>
                    </tr>
                }
                @if (nursingCareInsurance.deductible) {
                    <tr
                        [bigUiTableRow]
                        class="block md:table-row">
                        <td
                            [bigUiTableCell]
                            class="hidden md:table-cell"></td>
                        <td
                            [bigUiTableCell]
                            class="block md:table-cell">
                            {{ t('results.deductible') }}
                        </td>
                        <td
                            [bigUiTableCell]
                            class="block text-right md:table-cell">
                            {{ nursingCareInsurance.deductible | currency: 'EUR' }}
                        </td>
                    </tr>
                }
            }
            <!-- Eigenanteil total -->
            @if (calculationResult.totalDeductible; as totalDeductible) {
                <tr
                    [bigUiTableRow]
                    class="block md:table-row">
                    <td
                        [bigUiTableCell]
                        class="hidden md:table-cell"></td>
                    <td
                        [bigUiTableCell]
                        class="block md:table-cell">
                        <strong>
                            {{ t('results.monthlyDeductible') }}
                        </strong>
                    </td>
                    <td
                        [bigUiTableCell]
                        class="block text-right md:table-cell">
                        <strong>{{ totalDeductible | currency: 'EUR' }}</strong>
                    </td>
                </tr>
            }
            <!-- Arbeitgeberanteil total -->
            @if (calculationResult.totalContributionEmployer; as totalContributionEmployer) {
                <tr
                    [bigUiTableRow]
                    class="block md:table-row">
                    <td
                        [bigUiTableCell]
                        class="hidden md:table-cell"></td>
                    <td
                        [bigUiTableCell]
                        class="block md:table-cell">
                        <strong>
                            {{ t('results.monthlyEmployerContribution') }}
                        </strong>
                    </td>
                    <td
                        [bigUiTableCell]
                        class="block text-right md:table-cell">
                        <strong>{{ totalContributionEmployer | currency: 'EUR' }}</strong>
                    </td>
                </tr>
            }

            <!-- canClaimSickPay -->
            <tr
                [bigUiTableRow]
                class="block md:table-row">
                <td
                    [bigUiTableCell]
                    class="block border-t-2 border-double border-t-black md:table-cell">
                    <strong>
                        {{ t('results.calculationBasis') }}
                    </strong>
                </td>
                <td
                    [bigUiTableCell]
                    class="block border-t-2 border-double border-t-black md:table-cell">
                    {{ t('results.sicknessBenefitContribution') }}
                </td>
                <td
                    [bigUiTableCell]
                    class="block text-right border-t-2 border-double border-t-black md:table-cell">
                    @if (calculationResult.canClaimSickPay) {
                        <span>{{ t('results.yes') }}</span>
                    } @else {
                        <span>{{ t('results.no') }}</span>
                    }
                </td>
            </tr>
            <!-- contributionAssessmentLimit -->
            @if (calculationResult.showContributionAssessmentLimit) {
                <tr
                    [bigUiTableRow]
                    class="block md:table-row">
                    <td
                        [bigUiTableCell]
                        class="hidden md:table-cell"></td>
                    <td
                        [bigUiTableCell]
                        class="block md:table-cell">
                        {{ t('results.contributionAssessmentLimit') }}
                    </td>
                    <td
                        [bigUiTableCell]
                        class="block text-right md:table-cell">
                        {{ calculationResult.contributionAssessmentLimit | currency: 'EUR' }}
                    </td>
                </tr>
            }
            <!-- minimumAssessmentBasis -->
            @if (calculationResult.showMinimumAssessmentBasis) {
                <tr
                    [bigUiTableRow]
                    class="block md:table-row">
                    <td
                        [bigUiTableCell]
                        class="hidden md:table-cell"></td>
                    <td
                        [bigUiTableCell]
                        class="block md:table-cell">
                        {{ t('results.minimumAssessmentBasis') }}
                    </td>
                    <td
                        [bigUiTableCell]
                        class="block text-right md:table-cell">
                        {{ calculationResult.minimumAssessmentBasis | currency: 'EUR' }}
                    </td>
                </tr>
            }
        </big-ui-table>
        <p>{{ t('results.withoutGuarantee') }}</p>
    }
</ng-container>
