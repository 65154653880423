<ng-container *transloco="let t; prefix: 'stContributionCalculator'">
    @if (form && !hasDataLoadingError) {
        @if (isLoading) {
            <big-ui-spinner />
        }
        @if (taxonomyTerms$ | async; as taxonomyTerms) {
            <form
                [formGroup]="form"
                (ngSubmit)="submit($event, taxonomyTerms)">
                <big-ui-form-row
                    #insuranceTypeFormRow
                    [id]="'insuranceType'"
                    [label]="t('label.insuranceType')"
                    [isRequired]="true"
                    [errors]="form.controls.insuranceType.errors"
                    [errorOverrides]="selectErrorOverrides"
                    [showError]="hasBeenSubmitted">
                    <big-ui-select
                        [id]="'insuranceType'"
                        [isValid]="form.controls.insuranceType.valid"
                        [options]="insuranceTypeOptions"
                        [showValidation]="hasBeenSubmitted"
                        [isRequired]="true"
                        formControlName="insuranceType"
                        name="insuranceType"
                        (ngModelChange)="onInsuranceTypeChange($event)"
                        [bigStFieldTracking]="{
                            metadata,
                            fieldName: insuranceTypeFormRow.label!,
                            valid: !hasBeenSubmitted || form.controls.insuranceType.valid
                        }" />
                </big-ui-form-row>
                @if (form.controls.insuranceType.value) {
                    <ng-container [formGroupName]="'specificFields'">
                        @if (form.controls.specificFields?.controls?.hasChildren; as hasChildrenControl) {
                            <big-ui-form-row
                                #hasChildrenFormRow
                                [label]="t('label.hasChildren')"
                                [id]="'hasChildren'"
                                [isRequired]="true"
                                [errors]="hasChildrenControl.errors"
                                [errorOverrides]="selectErrorOverrides"
                                [showError]="hasBeenSubmitted"
                                radiogroupId="hasChildrenRadiogroup"
                                [infoTextTemplate]="socialWelfareInfo"
                                (infoLayerOpened)="trackStInfoIconEvent(hasChildrenFormRow.label!)">
                                <big-ui-button-group
                                    class="w-full"
                                    buttonRowClasses="w-full md:w-6/12 lg:w-4/12"
                                    [isValid]="hasChildrenControl.valid"
                                    [isTouched]="hasChildrenControl.touched"
                                    radiogroupId="hasChildrenRadiogroup"
                                    [options]="childrenOptions"
                                    [showValidation]="hasBeenSubmitted"
                                    [isRequired]="true"
                                    (ngModelChange)="onHasChildrenChange($event)"
                                    [id]="'hasChildren'"
                                    [bigStFieldTracking]="{
                                        metadata,
                                        fieldName: hasChildrenFormRow.label!,
                                        valid: !hasBeenSubmitted || hasChildrenControl.valid
                                    }"
                                    formControlName="hasChildren"
                                    name="hasChildren" />
                                <ng-template #socialWelfareInfo>
                                    {{
                                        t('label.childrenHelpLayer', {
                                            additionalRate: taxonomyTerms.additionalNursingCareInsuranceContributionRate * 100 | number: '1.1-3'
                                        })
                                    }}
                                </ng-template>
                            </big-ui-form-row>
                            @if (hasChildrenControl.value && form.controls.specificFields?.controls?.childrenUnder25; as childrenUnder25Control) {
                                <big-ui-form-row
                                    #childrenUnder25FormRow
                                    [id]="'childrenUnder25'"
                                    [label]="t('label.childrenUnder25')"
                                    [isRequired]="true"
                                    [errors]="childrenUnder25Control.errors"
                                    [errorOverrides]="selectErrorOverrides"
                                    [showError]="hasBeenSubmitted">
                                    <big-ui-select
                                        [id]="'childrenUnder25'"
                                        [isValid]="childrenUnder25Control.valid"
                                        [options]="childrenUnder25Options"
                                        [showValidation]="hasBeenSubmitted"
                                        [isRequired]="true"
                                        formControlName="childrenUnder25"
                                        name="childrenUnder25"
                                        [bigStFieldTracking]="{
                                            metadata,
                                            fieldName: childrenUnder25FormRow.label!,
                                            valid: !hasBeenSubmitted || childrenUnder25Control.valid
                                        }" />
                                </big-ui-form-row>
                            }
                        }
                        @if (form.controls.specificFields?.controls?.stateOfResidency; as stateOfResidencyControl) {
                            <big-ui-form-row
                                #stateOfResidencyFormRow
                                [label]="t('label.stateOfResidency')"
                                [id]="'stateOfResidency'"
                                [isRequired]="hasControlRequiredValidator(stateOfResidencyControl)"
                                [errors]="stateOfResidencyControl.errors"
                                [errorOverrides]="selectErrorOverrides"
                                [showError]="hasBeenSubmitted">
                                <big-ui-select
                                    [id]="'stateOfResidency'"
                                    [isValid]="stateOfResidencyControl.valid"
                                    [disableTransloco]="true"
                                    [options]="stateOfResidencyOptions"
                                    [showValidation]="hasBeenSubmitted"
                                    [isRequired]="true"
                                    formControlName="stateOfResidency"
                                    name="stateOfResidency"
                                    [bigStFieldTracking]="{
                                        metadata,
                                        fieldName: stateOfResidencyFormRow.label!,
                                        valid: !hasBeenSubmitted || stateOfResidencyControl.valid
                                    }" />
                            </big-ui-form-row>
                        }
                        @if (form.controls.specificFields?.controls?.monthlyGrossIncome; as monthlyGrossIncomeControl) {
                            <big-ui-form-row
                                #monthlyGrossIncomeFormRow
                                [label]="t('label.monthlyGrossIncome')"
                                [id]="'monthlyGrossIncome'"
                                [isRequired]="hasControlRequiredValidator(monthlyGrossIncomeControl)"
                                [errors]="monthlyGrossIncomeControl.errors"
                                [showError]="hasBeenSubmitted">
                                <big-ui-currency-input
                                    [isValid]="monthlyGrossIncomeControl.valid"
                                    [isTouched]="monthlyGrossIncomeControl.touched"
                                    [isRequired]="hasControlRequiredValidator(monthlyGrossIncomeControl)"
                                    [showValidation]="hasBeenSubmitted"
                                    [id]="'monthlyGrossIncome'"
                                    [bigStFieldTracking]="{
                                        metadata,
                                        fieldName: monthlyGrossIncomeFormRow.label!,
                                        valid: !hasBeenSubmitted || monthlyGrossIncomeControl.valid
                                    }"
                                    formControlName="monthlyGrossIncome" />
                            </big-ui-form-row>
                        }
                        @if (form.controls.specificFields?.controls?.grossPension; as grossPensionControl) {
                            <big-ui-form-row
                                #grossPensionFormRow
                                [label]="t('label.grossPension')"
                                [id]="'grossPension'"
                                [isRequired]="hasControlRequiredValidator(grossPensionControl)"
                                [errors]="grossPensionControl.errors"
                                [showError]="hasBeenSubmitted"
                                (infoLayerOpened)="trackStInfoIconEvent(grossPensionFormRow.label!)">
                                <big-ui-currency-input
                                    [isValid]="grossPensionControl.valid"
                                    [isTouched]="grossPensionControl.touched"
                                    [isRequired]="hasControlRequiredValidator(grossPensionControl)"
                                    [showValidation]="hasBeenSubmitted"
                                    [id]="'grossPension'"
                                    [bigStFieldTracking]="{
                                        metadata,
                                        fieldName: grossPensionFormRow.label!,
                                        valid: !hasBeenSubmitted || grossPensionControl.valid
                                    }"
                                    formControlName="grossPension" />
                            </big-ui-form-row>
                        }
                        @if (form.controls.specificFields?.controls?.companyPension; as companyPensionControl) {
                            <big-ui-form-row
                                #companyPensionFormRow
                                [label]="t('label.companyPension')"
                                [id]="'companyPension'"
                                [isRequired]="hasControlRequiredValidator(companyPensionControl)"
                                [errors]="companyPensionControl.errors"
                                [showError]="hasBeenSubmitted"
                                [infoTextTemplate]="form.controls.insuranceType.value === optionRetiredCompulsory ? companyPensionInfo : undefined"
                                (infoLayerOpened)="trackStInfoIconEvent(companyPensionFormRow.label!)">
                                <big-ui-currency-input
                                    [isValid]="companyPensionControl.valid"
                                    [isTouched]="companyPensionControl.touched"
                                    [isRequired]="hasControlRequiredValidator(companyPensionControl)"
                                    [showValidation]="hasBeenSubmitted"
                                    [id]="'companyPension'"
                                    [bigStFieldTracking]="{
                                        metadata,
                                        fieldName: companyPensionFormRow.label!,
                                        valid: !hasBeenSubmitted || companyPensionControl.valid
                                    }"
                                    formControlName="companyPension" />
                                <ng-template #companyPensionInfo>
                                    {{
                                        t('label.companyPensionHelp', {
                                            minimumIncomeCompanyPension: taxonomyTerms.minimumIncomeCompanyPension | currency: 'EUR'
                                        })
                                    }}
                                </ng-template>
                            </big-ui-form-row>
                        }
                        @if (form.controls.specificFields?.controls?.otherRevenue; as otherRevenueControl) {
                            <big-ui-form-row
                                #otherRevenueFormRow
                                [id]="'otherRevenue'"
                                [label]="t('label.otherRevenue')"
                                [isRequired]="false"
                                [errors]="otherRevenueControl.errors"
                                [errorOverrides]="selectErrorOverrides"
                                [showError]="hasBeenSubmitted"
                                [infoTextTemplate]="otherRevenueInfo"
                                (infoLayerOpened)="trackStInfoIconEvent(otherRevenueFormRow.label!)">
                                <big-ui-currency-input
                                    [isValid]="otherRevenueControl.valid"
                                    [isTouched]="otherRevenueControl.touched"
                                    [isRequired]="false"
                                    [showValidation]="hasBeenSubmitted"
                                    formControlName="otherRevenue"
                                    [bigStFieldTracking]="{
                                        metadata,
                                        fieldName: otherRevenueFormRow.label!,
                                        valid: !hasBeenSubmitted || otherRevenueControl.valid
                                    }" />
                                <ng-template #otherRevenueInfo>
                                    {{ t('label.otherRevenueHelp') }}
                                </ng-template>
                            </big-ui-form-row>
                        }
                        @if (form.controls.specificFields?.controls?.receivesSicknessPayout; as receivesSicknessPayoutControl) {
                            <big-ui-form-row
                                #receivesSicknessPayoutFormRow
                                [id]="'receivesSicknessPayout'"
                                [label]="t('label.receivesSicknessPayout')"
                                [isRequired]="true"
                                [errors]="receivesSicknessPayoutControl.errors"
                                [errorOverrides]="selectErrorOverrides"
                                [showError]="hasBeenSubmitted"
                                radiogroupId="receivesSicknessPayoutRadioGroup"
                                [infoTextTemplate]="receivesSicknessPayoutInfo"
                                (infoLayerOpened)="trackStInfoIconEvent(receivesSicknessPayoutFormRow.label!)">
                                <big-ui-button-group
                                    class="w-full"
                                    buttonRowClasses="w-full md:w-6/12 lg:w-4/12"
                                    [isValid]="receivesSicknessPayoutControl.valid"
                                    [isTouched]="receivesSicknessPayoutControl.touched"
                                    radiogroupId="receivesSicknessPayoutRadioGroup"
                                    [options]="childrenOptions"
                                    [showValidation]="hasBeenSubmitted"
                                    [isRequired]="true"
                                    formControlName="receivesSicknessPayout"
                                    name="receivesSicknessPayout"
                                    [bigStFieldTracking]="{
                                        metadata,
                                        fieldName: receivesSicknessPayoutFormRow.label!,
                                        valid: !hasBeenSubmitted || receivesSicknessPayoutControl.valid
                                    }" />
                                <ng-template #receivesSicknessPayoutInfo>
                                    {{
                                        t('label.receivesSicknessPayoutHelp', {
                                            reductionRate:
                                                (taxonomyTerms.healthInsuranceContributionRate - taxonomyTerms.healthInsuranceContributionRateDiscounted) * 100
                                                | number: '1.1-3',
                                            finalRate:
                                                (taxonomyTerms.healthInsuranceContributionRateDiscounted +
                                                    taxonomyTerms.additionalHealthInsuranceContributionRate) *
                                                    100 | number: '1.1-3'
                                        })
                                    }}
                                </ng-template>
                            </big-ui-form-row>
                        }
                    </ng-container>
                }
                <big-ui-button
                    [buttonStyle]="'secondary'"
                    [id]="'submit'"
                    [type]="'submit'"
                    class="block my-10 last:mb-0">
                    {{ t('label.submit') }}
                </big-ui-button>
            </form>
            @if (calculationResult) {
                <big-service-tool-contribution-calculator-result
                    [calculationResult]="calculationResult"
                    [taxonomies]="taxonomyTerms"
                    class="mt-10" />
            }
        }
    } @else {
        <big-ui-alert
            [title]="t('error.title')"
            [classList]="'flex text-center border-2 rounded-md flex-column !p-4 mt-10'"
            type="danger">
            {{ t('error.unavailable', { number: errorPhoneNumber.title }) }}
        </big-ui-alert>
    }

    <ng-template #error>
        <big-ui-alert
            [title]="t('error.title')"
            [classList]="'flex text-center border-2 rounded-md flex-column !p-4 mt-10'"
            type="danger">
            {{ t('error.unavailable') }}
        </big-ui-alert>
    </ng-template>
</ng-container>
