import { Injectable } from '@angular/core';
import { type OtherVoluntarilyInsuredForm, type Premium, type TaxonomyData } from '../models/insurant-calculator.model';
import { CalculatorService } from './calculator.service';

@Injectable({
    providedIn: 'root',
})
export class OtherVoluntarilyCalculatorService {
    private calculator!: CalculatorService;

    public calculatePremium(formData: OtherVoluntarilyInsuredForm, taxonomies: TaxonomyData): Premium {
        this.calculator = new CalculatorService(taxonomies);

        const { hasChildren } = formData;
        const childrenAmount = formData.childrenUnder25 ?? 0;

        const {
            requiredBasis,
            supplementAmount = 0,
            showContributionAssessmentLimit,
            showMinimumAssessmentBasis,
        } = this.calculator.getCalculationBasis(formData.monthlyGrossIncome);
        const incomeBasis = requiredBasis + supplementAmount;

        const healthInsuranceShareFull = this.calculator.getHealthInsuranceShare(incomeBasis, true, 1);
        const nursingCareInsuranceDeductible = this.calculator.getNursingCareInsuranceDeductible(hasChildren, childrenAmount, 0, incomeBasis);

        return this.calculator.getPremium(
            0,
            0,
            healthInsuranceShareFull,
            0,
            nursingCareInsuranceDeductible,
            {
                showContributionAssessmentLimit,
                showMinimumAssessmentBasis,
            },
        );
    }
}
