/* eslint-disable @typescript-eslint/no-magic-numbers */
import { type PremiumParams } from '../models/contribution-calculator-result';
import { type CalculationBasis, type Premium, type TaxonomyData } from '../models/insurant-calculator.model';

export class CalculatorService {
    public readonly halfDivider = 2;
    public readonly oneHundredPercent = 100;
    private readonly defaultPremiumParams = {
        canClaimSickPay: false,
        showContributionAssessmentLimit: false,
        showMinimumAssessmentBasis: false,
        hasSlidingPayScale: false,
        isMarginallyEmployed: false,
    };

    private readonly healthInsuranceGeneralContributionRate =
        this.taxonomies.healthInsuranceContributionRate + this.taxonomies.additionalHealthInsuranceContributionRate;
    private readonly healthInsuranceGeneralContributionRateDiscounted =
        this.taxonomies.healthInsuranceContributionRateDiscounted + this.taxonomies.additionalHealthInsuranceContributionRate;
    private readonly halfHealthInsuranceGeneralContributionRate = this.healthInsuranceGeneralContributionRate / this.halfDivider;
    private readonly nursingCareContributionRate = this.taxonomies.nursingCareInsuranceContributionRate / this.halfDivider;

    public constructor(private readonly taxonomies: TaxonomyData) {}

    public round(number: number): number {
        return Math.round(number * this.oneHundredPercent) / this.oneHundredPercent;
    }

    public showContributionAssessmentLimit(totalIncome: number, contributionAssessmentLimit: number): boolean {
        return totalIncome > contributionAssessmentLimit;
    }

    public showMinimumAssessmentBasis(totalIncome: number, minimumAssessmentBasis: number): boolean {
        return totalIncome < minimumAssessmentBasis;
    }

    public subtractExemptAmount(value: number, exemptAmount: number): number {
        return value >= exemptAmount ? value - exemptAmount : 0;
    }

    public getCalculationBasis(
        requiredValue: number,
        optionalValueA = 0,
        optionalValueB = 0,
        minimumAssessmentBasis = this.taxonomies.minimumIncomeSelfEmployed,
    ): CalculationBasis {
        const { contributionAssessmentLimit } = this.taxonomies;
        const calculationBasis = {
            requiredBasis: requiredValue,
            optionalBasisA: optionalValueA,
            optionalBasisB: optionalValueB,
            showMinimumAssessmentBasis: false,
            showContributionAssessmentLimit: false,
        };

        if (requiredValue + optionalValueA + optionalValueB <= minimumAssessmentBasis) {
            return {
                ...calculationBasis,
                supplementAmount: minimumAssessmentBasis - (requiredValue + optionalValueA + optionalValueB),
                showMinimumAssessmentBasis: true,
            };
        }

        if (requiredValue >= contributionAssessmentLimit) {
            return {
                ...calculationBasis,
                optionalBasisA: 0,
                optionalBasisB: 0,
                requiredBasis: contributionAssessmentLimit,
                showContributionAssessmentLimit: true,
            };
        }

        if (requiredValue + optionalValueA >= contributionAssessmentLimit) {
            return {
                ...calculationBasis,
                optionalBasisA: contributionAssessmentLimit - requiredValue,
                optionalBasisB: 0,
                showContributionAssessmentLimit: true,
            };
        }

        if (requiredValue + optionalValueA + optionalValueB >= contributionAssessmentLimit) {
            return {
                ...calculationBasis,
                optionalBasisB: contributionAssessmentLimit - requiredValue - optionalValueA,
                showContributionAssessmentLimit: true,
            };
        }

        return calculationBasis;
    }

    public getHealthInsuranceShare(income: number, useDiscountedRate = false, rateMultiplier = 0.5): number {
        const rate = useDiscountedRate ? this.healthInsuranceGeneralContributionRateDiscounted : this.healthInsuranceGeneralContributionRate;

        return this.round(income * rate * rateMultiplier);
    }

    public getHealthInsuranceDeductible(healthInsuranceEmployerShare: number, pensionBasis: number, companyPensionBasis: number): number {
        const pensionDeductible = this.round(pensionBasis * this.halfHealthInsuranceGeneralContributionRate);
        const companyPension = this.round(companyPensionBasis * this.healthInsuranceGeneralContributionRate);

        return this.round(healthInsuranceEmployerShare + pensionDeductible + companyPension);
    }

    public getNursingCareInsuranceEmployerShare(income: number, residentOfSaxony = false): number {
        if (residentOfSaxony) {
            const lowerEmployerContributionRate = this.nursingCareContributionRate - 0.005;

            return this.round(income * lowerEmployerContributionRate);
        }

        return this.round(income * this.nursingCareContributionRate);
    }

    public getNursingCareInsuranceDeductible(
        hasChildren: boolean,
        childrenAmount: number,
        nursingCareEmployerShare: number,
        requiredIncome: number,
        optionalIncomeOne = 0,
        optionalIncomeTwo = 0,
    ): number {
        const { nursingCareInsuranceContributionRate, additionalNursingCareInsuranceContributionRate } = this.taxonomies;

        const nursingCareDeductibleRateFactor = this.getChildDiscount(childrenAmount);

        const nursingCareDeductibleRate = hasChildren
            ? nursingCareDeductibleRateFactor
            : nursingCareInsuranceContributionRate + additionalNursingCareInsuranceContributionRate;

        return this.round(this.round((requiredIncome + optionalIncomeOne + optionalIncomeTwo) * nursingCareDeductibleRate) - nursingCareEmployerShare);
    }

    public getPremium(
        healthInsuranceEmployerShare: number,
        healthInsurancePensionInsuranceShare: number,
        healthInsuranceDeductible: number,
        nursingCareInsuranceEmployerShare: number,
        nursingCareInsuranceDeductible: number,
        premiumParams: Partial<PremiumParams>,
    ): Premium {
        const { contributionAssessmentLimit, minimumIncomeSelfEmployed } = this.taxonomies;
        const healthInsuranceTotal = this.round(healthInsuranceEmployerShare + healthInsuranceDeductible + healthInsurancePensionInsuranceShare);
        const nursingCareInsuranceTotal = this.round(nursingCareInsuranceEmployerShare + nursingCareInsuranceDeductible);
        const totalContributionEmployer = this.round(healthInsuranceEmployerShare + nursingCareInsuranceEmployerShare);
        const totalDeductible = this.round(healthInsuranceDeductible + nursingCareInsuranceDeductible);

        return {
            year: new Date().getFullYear(),
            totalContributionEmployer,
            totalDeductible,
            monthlyContribution: {
                healthInsurance: {
                    employerShare: healthInsuranceEmployerShare,
                    pensionInsuranceShare: healthInsurancePensionInsuranceShare,
                    deductible: healthInsuranceDeductible,
                    total: healthInsuranceTotal,
                },
                nursingCareInsurance: {
                    employerShare: nursingCareInsuranceEmployerShare,
                    deductible: nursingCareInsuranceDeductible,
                    total: nursingCareInsuranceTotal,
                },
            },
            contributionAssessmentLimit,
            minimumAssessmentBasis: minimumIncomeSelfEmployed,
            ...this.defaultPremiumParams,
            ...premiumParams,
        };
    }

    private getChildDiscount(amountChildren: number): number {
        const {
            nursingCareInsuranceContributionRate: baseRate,
            nursingCareDiscountOneChild: children1,
            nursingCareDiscountTwoChildren: children2,
            nursingCareDiscountThreeChildren: children3,
            nursingCareDiscountFourChildren: children4,
            nursingCareDiscountFiveChildren: children5,
        } = this.taxonomies;

        switch (amountChildren) {
            case 0:
                return baseRate;
            case 1:
                return baseRate - children1;
            case 2:
                return baseRate - children2;
            case 3:
                return baseRate - children3;
            case 4:
                return baseRate - children4;
            case 5:
                return baseRate - children5;
            default:
                return baseRate;
        }
    }
}
