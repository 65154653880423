import { inject, Injectable } from '@angular/core';
import { type FormGroup } from '@angular/forms';
import { type ContributionCalculatorForm } from '../models/contribution-calculator-form.model';
import {
    type EmployeeCompulsoryForm,
    type EmployeeVoluntarilyInsuredForm,
    type IndependentlyInsuredForm,
    type OtherVoluntarilyInsuredForm,
    type Premium,
    type RetiredCompulsoryForm,
    type RetiredVoluntarilyInsuredForm,
    type TaxonomyData,
} from '../models/insurant-calculator.model';
import { EmployeeCalculatorService } from './employee-calculator.service';
import { EmployeeVoluntarilyInsuredCalculatorService } from './employee-voluntarily-insured-calculator.service';
import { IndependentCalculatorService } from './independent-calculator.service';
import { OtherVoluntarilyCalculatorService } from './other-voluntarily-calculator.service';
import { RetiredCalculatorService } from './retired-calculator.service';
import { RetiredVoluntarilyInsuredCalculatorService } from './retired-voluntarily-insured-calculator.service';

@Injectable({
    providedIn: 'root',
})
export class ContributionCalculatorTypeService {
    private readonly employeeCalculator = inject(EmployeeCalculatorService);
    private readonly employeeVoluntarilyInsuredCalculator = inject(EmployeeVoluntarilyInsuredCalculatorService);
    private readonly retiredCalculator = inject(RetiredCalculatorService);
    private readonly retiredVoluntarilyInsuredCalculator = inject(RetiredVoluntarilyInsuredCalculatorService);
    private readonly independentCalculator = inject(IndependentCalculatorService);
    private readonly otherVoluntarilyCalculator = inject(OtherVoluntarilyCalculatorService);

    public calculateByInsuranceType(formData: FormGroup<ContributionCalculatorForm>, taxonomies: TaxonomyData): Premium | undefined {
        switch (formData.value.insuranceType) {
            case 'employeeCompulsory':
                return this.employeeCalculator.calculatePremium(formData.value.specificFields as EmployeeCompulsoryForm, taxonomies);
            case 'employeeVoluntary':
                return this.employeeVoluntarilyInsuredCalculator.calculatePremium(formData.value.specificFields as EmployeeVoluntarilyInsuredForm, taxonomies);
            case 'retiredCompulsory':
                return this.retiredCalculator.calculatePremium(formData.value.specificFields as RetiredCompulsoryForm, taxonomies);
            case 'retiredVoluntary':
                return this.retiredVoluntarilyInsuredCalculator.calculatePremium(formData.value.specificFields as RetiredVoluntarilyInsuredForm, taxonomies);
            case 'independent':
                return this.independentCalculator.calculatePremium(formData.value.specificFields as IndependentlyInsuredForm, taxonomies);
            case 'otherVoluntary':
                return this.otherVoluntarilyCalculator.calculatePremium(formData.value.specificFields as OtherVoluntarilyInsuredForm, taxonomies);
            default:
                return undefined;
        }
    }
}
