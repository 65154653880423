/* eslint-disable max-lines, complexity */
import { Injectable } from '@angular/core';
import { type Premium, type RetiredCompulsoryForm, type TaxonomyData } from './../models/insurant-calculator.model';
import { CalculatorService } from './calculator.service';

@Injectable({
    providedIn: 'root',
})
export class RetiredCalculatorService {
    private calculator!: CalculatorService;

    public calculatePremium(formData: RetiredCompulsoryForm, taxonomies: TaxonomyData): Premium {
        this.calculator = new CalculatorService(taxonomies);
        const {
            contributionAssessmentLimit,
            minimumIncomeCompanyPension,
        } = taxonomies;


        const { hasChildren } = formData;
        const childrenAmount = formData.childrenUnder25 ?? 0;

        const {
            requiredBasis: pensionBasis,
            optionalBasisA,
            optionalBasisB,
            showContributionAssessmentLimit,
            showMinimumAssessmentBasis,
        } = this.calculator.getCalculationBasis(
            formData.grossPension,
            formData.monthlyGrossIncome,
            this.calculator.subtractExemptAmount(formData.companyPension ?? 0, minimumIncomeCompanyPension),
            0,
        );

        const incomeBasis = optionalBasisA;
        const companyPensionBasis = optionalBasisB;
        let companyPensionCalculated =
            formData.grossPension + (formData.monthlyGrossIncome ?? 0) + (formData.companyPension ?? 0) >= contributionAssessmentLimit
                ? contributionAssessmentLimit - formData.grossPension - (formData.monthlyGrossIncome ?? 0)
                : formData.companyPension ?? 0;
        companyPensionCalculated = companyPensionCalculated < 0 ? 0 : companyPensionCalculated;

        const healthInsuranceEmployerShare = this.calculator.getHealthInsuranceShare(
            incomeBasis,
            true,
        );

        const healthInsuranceDeductible = this.calculator.getHealthInsuranceDeductible(
            healthInsuranceEmployerShare,
            pensionBasis,
            companyPensionBasis,
        );

        const healthInsurancePensionShare = this.calculator.getHealthInsuranceShare(pensionBasis);

        const nursingCareInsuranceEmployerShare = this.calculator.getNursingCareInsuranceEmployerShare(incomeBasis);
        const nursingCareInsuranceDeductible = this.calculator.getNursingCareInsuranceDeductible(
            hasChildren,
            childrenAmount,
            nursingCareInsuranceEmployerShare,
            pensionBasis,
            incomeBasis,
            companyPensionCalculated,
        );

        return this.calculator.getPremium(
            healthInsuranceEmployerShare,
            healthInsurancePensionShare,
            healthInsuranceDeductible,
            nursingCareInsuranceEmployerShare,
            nursingCareInsuranceDeductible,
            {
                canClaimSickPay: incomeBasis ? incomeBasis < 0 : false,
                showContributionAssessmentLimit,
                showMinimumAssessmentBasis,
            },
        );
    }
}
