import { Injectable } from '@angular/core';
import { type Premium, type RetiredVoluntarilyInsuredForm, type TaxonomyData } from '../models/insurant-calculator.model';
import { CalculatorService } from './calculator.service';

@Injectable({
    providedIn: 'root',
})
export class RetiredVoluntarilyInsuredCalculatorService {
    private calculator!: CalculatorService;

    public calculatePremium(formData: RetiredVoluntarilyInsuredForm, taxonomies: TaxonomyData): Premium {
        this.calculator = new CalculatorService(taxonomies);

        const { childrenUnder25 = 0, hasChildren } = formData;

        const {
            requiredBasis,
            optionalBasisA = 0,
            optionalBasisB = 0,
            showContributionAssessmentLimit,
            showMinimumAssessmentBasis,
            supplementAmount = 0,
        } = this.calculator.getCalculationBasis(formData.grossPension, formData.companyPension, formData.otherRevenue);

        const pensionBasis = requiredBasis;
        const companyPensionBasis = optionalBasisA;
        const otherRevenueBasis = optionalBasisB;

        const nursingCareInsuranceDeductible = this.calculator.getNursingCareInsuranceDeductible(
            hasChildren,
            childrenUnder25,
            0,
            pensionBasis,
            companyPensionBasis,
            this.calculator.round(otherRevenueBasis + supplementAmount),
        );

        const healthInsurancePensionShare = this.calculator.getHealthInsuranceShare(pensionBasis);

        const pensionPart = this.calculator.getHealthInsuranceShare(pensionBasis, false, 1);
        const companyPensionPart = this.calculator.getHealthInsuranceShare(companyPensionBasis, false, 1);
        const otherRevenuePart = this.calculator.getHealthInsuranceShare(otherRevenueBasis, true, 1);
        const supplementAmountPart = this.calculator.getHealthInsuranceShare(supplementAmount, true, 1);
        const healthInsuranceDeductible = this.calculator.round(
            pensionPart +
            companyPensionPart +
            otherRevenuePart +
            supplementAmountPart -
            healthInsurancePensionShare,
        );

        return this.calculator.getPremium(
            0,
            healthInsurancePensionShare,
            healthInsuranceDeductible,
            0,
            nursingCareInsuranceDeductible,
            {
                showContributionAssessmentLimit,
                showMinimumAssessmentBasis,
            },
        );
    }
}
