import { Injectable } from '@angular/core';
import { type IndependentlyInsuredForm, type Premium, type TaxonomyData } from '../models/insurant-calculator.model';
import { CalculatorService } from './calculator.service';

@Injectable({
    providedIn: 'root',
})
export class IndependentCalculatorService {
    private calculator!: CalculatorService;

    public calculatePremium(formData: IndependentlyInsuredForm, taxonomies: TaxonomyData): Premium {
        this.calculator = new CalculatorService(taxonomies);

        const canClaimSickPay = formData.receivesSicknessPayout;
        const childrenAmount = formData.childrenUnder25 ?? 0;
        const { hasChildren } = formData;

        const {
            requiredBasis,
            supplementAmount = 0,
            showContributionAssessmentLimit,
            showMinimumAssessmentBasis,
        } = this.calculator.getCalculationBasis(formData.monthlyGrossIncome);
        const incomeBasis = requiredBasis + supplementAmount;

        const healthInsuranceDeductible = this.calculator.getHealthInsuranceShare(
            incomeBasis,
            !canClaimSickPay,
            1,
        );

        const nursingCareInsuranceDeductible = this.calculator.getNursingCareInsuranceDeductible(hasChildren, childrenAmount, 0, incomeBasis);

        return this.calculator.getPremium(
            0,
            0,
            healthInsuranceDeductible,
            0,
            nursingCareInsuranceDeductible,
            {
                canClaimSickPay,
                showContributionAssessmentLimit,
                showMinimumAssessmentBasis,
            },
        );
    }
}
