import { Injectable } from '@angular/core';
import { type EmployeeCompulsoryForm, type Premium, type TaxonomyData } from '../models/insurant-calculator.model';
import { CalculatorService } from './calculator.service';

@Injectable({
    providedIn: 'root',
})
export class EmployeeCalculatorService {
    private calculator!: CalculatorService;

    public calculatePremium(formData: EmployeeCompulsoryForm, taxonomies: TaxonomyData): Premium | undefined {
        this.calculator = new CalculatorService(taxonomies);

        const { slidingPayScaleFloor, slidingPayScaleCeiling } = taxonomies;

        const childrenAmount = formData.childrenUnder25 ?? 0;
        const residesInSaxony = formData.stateOfResidency === 'Sachsen';
        const { hasChildren } = formData;

        const {
            requiredBasis: incomeBasis,
            optionalBasisA: pensionBasis,
            optionalBasisB: companyPensionBasis,
            showContributionAssessmentLimit,
            showMinimumAssessmentBasis,
        } = this.calculator.getCalculationBasis(formData.monthlyGrossIncome, formData.grossPension, formData.companyPension);

        const healthInsuranceEmployerShare = this.calculator.getHealthInsuranceShare(incomeBasis);
        const healthInsuranceDeductible = this.calculator.getHealthInsuranceDeductible(healthInsuranceEmployerShare, pensionBasis, companyPensionBasis);
        const healthInsurancePensionShare = this.calculator.getHealthInsuranceShare(pensionBasis);
        const nursingCareInsuranceEmployerShare = this.calculator.getNursingCareInsuranceEmployerShare(incomeBasis, residesInSaxony);
        const nursingCareInsuranceDeductible = this.calculator.getNursingCareInsuranceDeductible(
            hasChildren,
            childrenAmount,
            nursingCareInsuranceEmployerShare,
            incomeBasis,
            pensionBasis,
            companyPensionBasis,
        );

        return this.calculator.getPremium(
            healthInsuranceEmployerShare,
            healthInsurancePensionShare,
            healthInsuranceDeductible,
            nursingCareInsuranceEmployerShare,
            nursingCareInsuranceDeductible,
            {
                showContributionAssessmentLimit,
                hasSlidingPayScale: this.hasSlidingPayScale(formData.monthlyGrossIncome, slidingPayScaleFloor, slidingPayScaleCeiling),
                isMarginallyEmployed: this.isMarginallyEmployed(formData.monthlyGrossIncome, slidingPayScaleFloor),
                showMinimumAssessmentBasis,
                canClaimSickPay: true,
            },
        );
    }

    private isMarginallyEmployed(grossIncome: number, slidingPayScaleFloor: number): boolean {
        return grossIncome <= slidingPayScaleFloor;
    }

    private hasSlidingPayScale(grossIncome: number, slidingPayScaleFloor: number, slidingPayScaleCeiling: number): boolean {
        return grossIncome > slidingPayScaleFloor && grossIncome <= slidingPayScaleCeiling;
    }
}
