/* eslint-disable @typescript-eslint/unbound-method */
import { inject, Injectable } from '@angular/core';
import { FormBuilder, FormControl, Validators, type FormGroup } from '@angular/forms';
import {
    type EmployeeCompulsoryInsuredForm,
    type EmployeeVoluntarilyInsuredForm,
    type IndependentlyInsuredForm,
    type OtherVoluntarilyInsuredForm,
    type RetiredCompulsorilyInsuredForm,
    type RetiredVoluntarilyInsuredForm,
} from '../models/contribution-calculator-form.model';
import { type InsuranceType } from '../models/insurance-types.model';

@Injectable({
    providedIn: 'root',
})
export class ContributionCalculatorFormService {
    private readonly optionEmployeeCompulsory: InsuranceType = 'employeeCompulsory';
    private readonly optionEmployeeVoluntary: InsuranceType = 'employeeVoluntary';
    private readonly optionIndependent: InsuranceType = 'independent';
    private readonly optionOtherVoluntary: InsuranceType = 'otherVoluntary';
    private readonly optionRetiredCompulsory: InsuranceType = 'retiredCompulsory';
    private readonly optionRetiredVoluntary: InsuranceType = 'retiredVoluntary';

    private readonly formBuilder: FormBuilder = inject(FormBuilder);

    public getInsuranceSpecificForm(insuranceType: InsuranceType): FormGroup {
        switch (insuranceType) {
            case this.optionRetiredVoluntary:
                return this.getRetiredVoluntaryForm();
            case this.optionRetiredCompulsory:
                return this.getRetiredCompulsoryForm();
            case this.optionEmployeeVoluntary:
                return this.getEmployeeVoluntaryForm();
            case this.optionEmployeeCompulsory:
                return this.getEmployeeCompulsoryForm();
            case this.optionIndependent:
                return this.getIndependentlyInsuredForm();
            case this.optionOtherVoluntary:
                return this.getOtherVoluntaryInsuredForm();
            default:
                throw new Error(`unknown insurance type: ${insuranceType}`);
        }
    }

    private getEmployeeCompulsoryForm(): FormGroup<EmployeeCompulsoryInsuredForm> {
        return this.formBuilder.group({
            hasChildren: new FormControl<boolean | null | undefined>(undefined, [Validators.required]),
            childrenUnder25: new FormControl<number | null | undefined>(undefined, [Validators.required]),
            monthlyGrossIncome: new FormControl<number | null | undefined>(undefined, [Validators.required]),
            stateOfResidency: new FormControl<string | null>('', [Validators.required]),
            grossPension: new FormControl<number | null | undefined>(undefined),
            companyPension: new FormControl<number | null | undefined>(undefined),
        });
    }

    private getEmployeeVoluntaryForm(): FormGroup<EmployeeVoluntarilyInsuredForm> {
        return this.formBuilder.group({
            hasChildren: new FormControl<boolean | null | undefined>(undefined, [Validators.required]),
            childrenUnder25: new FormControl<number | null | undefined>(undefined, [Validators.required]),
            monthlyGrossIncome: new FormControl<number | null | undefined>(undefined, [Validators.required]),
            stateOfResidency: new FormControl<string | null>('', [Validators.required]),
        });
    }

    private getRetiredCompulsoryForm(): FormGroup<RetiredCompulsorilyInsuredForm> {
        return this.formBuilder.group({
            hasChildren: new FormControl<boolean | null | undefined>(undefined, [Validators.required]),
            childrenUnder25: new FormControl<number | null | undefined>(undefined, [Validators.required]),
            monthlyGrossIncome: new FormControl<number | null | undefined>(undefined),
            grossPension: new FormControl<number | null | undefined>(undefined, [Validators.required]),
            companyPension: new FormControl<number | null | undefined>(undefined),
        });
    }

    private getRetiredVoluntaryForm(): FormGroup<RetiredVoluntarilyInsuredForm> {
        return this.formBuilder.group({
            hasChildren: new FormControl<boolean | null | undefined>(undefined, [Validators.required]),
            childrenUnder25: new FormControl<number | null | undefined>(undefined, [Validators.required]),
            grossPension: new FormControl<number | null | undefined>(undefined, [Validators.required]),
            companyPension: new FormControl<number | null | undefined>(undefined),
            otherRevenue: new FormControl<number | null | undefined>(undefined),
        });
    }

    private getIndependentlyInsuredForm(): FormGroup<IndependentlyInsuredForm> {
        return this.formBuilder.group({
            hasChildren: new FormControl<boolean | null | undefined>(undefined, [Validators.required]),
            childrenUnder25: new FormControl<number | null | undefined>(undefined, [Validators.required]),
            monthlyGrossIncome: new FormControl<number | null | undefined>(undefined, [Validators.required]),
            receivesSicknessPayout: new FormControl<boolean | null | undefined>(undefined, [Validators.required]),
        });
    }

    private getOtherVoluntaryInsuredForm(): FormGroup<OtherVoluntarilyInsuredForm> {
        return this.formBuilder.group({
            hasChildren: new FormControl<boolean | null | undefined>(undefined, [Validators.required]),
            childrenUnder25: new FormControl<number | null | undefined>(undefined, [Validators.required]),
            monthlyGrossIncome: new FormControl<number | null | undefined>(undefined, [Validators.required]),
        });
    }
}
