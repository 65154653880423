import { CurrencyPipe, DecimalPipe } from '@angular/common';
import { ChangeDetectionStrategy, Component, HostBinding, Input, inject, reflectComponentType, type AfterViewInit, type OnChanges } from '@angular/core';
import { UiTableCellDirective, UiTableComponent, UiTableRowDirective } from '@big-direkt/ui/table';
import { EnvironmentService, ScrollService } from '@big-direkt/utils/environment';
import { type TableHeaderCellModel, type TypedSimpleChanges } from '@big-direkt/utils/shared';
import { TranslocoDirective } from '@jsverse/transloco';
import { ContributionCalculatorFormResult } from '../models/contribution-calculator-result';
import { TaxonomyData } from '../models/insurant-calculator.model';

@Component({
    selector: 'big-service-tool-contribution-calculator-result',
    templateUrl: './contribution-calculator-result.component.html',
    standalone: true,
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [CurrencyPipe, UiTableCellDirective, UiTableRowDirective],
    imports: [CurrencyPipe, DecimalPipe, TranslocoDirective, UiTableComponent, UiTableCellDirective, UiTableRowDirective],
})
export class ContributionCalculatorResultComponent implements OnChanges, AfterViewInit {
    private readonly environmentService = inject(EnvironmentService);
    private readonly scrollService = inject(ScrollService);

    public readonly servicePhoneNumber = this.environmentService.servicePhoneNumber;

    @HostBinding('class') @Input() public classList = 'block';

    @Input({ required: true }) public calculationResult!: ContributionCalculatorFormResult;
    @Input({ required: true }) public taxonomies!: TaxonomyData;

    public tableHeaders: TableHeaderCellModel[] = [
        {
            value: 'stContributionCalculator.results.title',
            colSpan: 3,
        },
    ];

    public ngOnChanges(changes: TypedSimpleChanges<ContributionCalculatorResultComponent>): void {
        if (changes.calculationResult?.currentValue && !changes.calculationResult.firstChange) {
            this.scrollIntoView();
        }
    }

    public ngAfterViewInit(): void {
        this.scrollIntoView();
    }

    private scrollIntoView(): void {
        const scrollElement = reflectComponentType(ContributionCalculatorResultComponent)?.selector;
        this.scrollService.scroll(scrollElement);
    }
}
